<template>
  <b-row>
    <b-col
      sm="12"
    >
      <b-card-code
        title="Agregar un nuevo Agente"
      >
        <b-card-body>
          <FormCreateComponent
            v-if="accessPermissions('users.store')"
          />
        </b-card-body>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow,
  BCol,
  BCardBody,
} from 'bootstrap-vue'
import FormCreateComponent from './components/FormCreateComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'AgentCreatePage',
  components: {
    BCardCode,
    BRow,
    BCol,
    BCardBody,
    FormCreateComponent,
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
  },
}
</script>
